import Vue from 'vue';
import Vuex from 'vuex';

import ahref_normalized from '../directives/ahref-normalized';
import xss_sanitized from '../directives/xss-sanitized';

import auth from './modules/auth';
import response from './modules/response';
import project from './modules/project';
import serviceline from './modules/serviceline';
import permissions from './modules/permissions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    project,
    serviceline,
    auth,
    response,
    permissions
  }
});

ahref_normalized.install();
xss_sanitized.install();