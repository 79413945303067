import store from '@/store';
import { AUTHENTICATED } from '@/store/statuses/auth';



export default function beforeEach(to, from, next) {
  const authenticated = store.state.auth.status === AUTHENTICATED;
  const requiresAuth = to.matched.some(record => record.meta?.requiresAuth == undefined ? true : record.meta.requiresAuth);
  const requiresQuestionnaire = store.state.auth.isQuestionnaireOn === 'true';


  if (requiresAuth && !authenticated) {
    next({ name: 'LoginPage', query: { from: window.location.pathname }});
  } else if (to.name === 'LoginPage' && authenticated) {
    if (requiresQuestionnaire) { // if the user needs to answer the first-login questionnaire they'll just go through to the login page
      next();
    } else { // if they don't need to answer it, just redirect them to the main project list page
      next(to.query.from || { name: 'AppContainer' });
    }
  } else {
    if (requiresQuestionnaire) { // redirect the user back to the login page if they need to answer the questionnaire
      next({ name: 'LoginPage', query: { from: window.location.pathname }});
    } else {
      next();
    }
  }
}