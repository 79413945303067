import Vue from 'vue';

/**
 * Normalize all <a> child elements, adding common properties.
 *
 * Use it when you have <a> elements given by user input and you don't want users
 * to remeber to add those properties all the time. Also it would be usefull when you have several
 * <a> elements under the same parent and you want them to have the same set of properties.
 */
export default {
  install: function() {
    Vue.directive('ahef-normalized', {
      inserted: function(el) {
        const el_a = el.getElementsByTagName('a');
        el_a.forEach(element => {

          // Open the links on a different page
          element.target = '_blank';

          // Avoid security issues with target _blank:
          // https://developer.chrome.com/docs/lighthouse/best-practices/external-anchors-use-rel-noopener/
          element.rel = 'noopener';
        });
      }
    });
  }
};