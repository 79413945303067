import axios from 'axios';

export default {
  async login(username, password) {
    const response = await axios.post('/authjwt/create/', { username: username, password: password });
    return response.data;
  },
  async loginWithOneTimePassword(payload) {
    const response = await axios.post('/authjwt/create/', payload);
    return response.data;
  },
  async signup(userData) {
    const response = await axios.post('/authusers/', userData);
    return response.data;
  },
  async sendPasswordResetEmail(email) {
    await axios.post('/authusers/reset_password/',{ email: email });
  },
  async resetPassword(resetPasswordPayload) {
    await axios.post('/authusers/reset_password_confirm/', resetPasswordPayload);
  },
  async getWebsocketClientAccessToken() {
    const response = await axios.get('/socket/negotiate');
    return response;
  },
  async getLoggedInUser() {
    const response = await axios.get('/authusers/me/');
    return response.data;
  },
  async getCountries() {
    const response = await axios.get('/api/countries');
    return response.data;
  },
  async getProvinces() {
    const response = await axios.get('/api/provinces');
    return response.data;
  },
  async questionnaireAnswers(answers) {
    const response = await axios.post('api/user/questionnaire-answers', answers);
    return response.data;
  },
  async generateOneTimePassword(payload) {
    const response = await axios.post('api/one-time-password/generate', payload);
    return response.data;
  }
};