const STUDY_YELLOW = { fill: { color: 0xFFC709, opacity: 1 }, stroke: { color: 0xFFC709, opacity: 1 } };
const STUDY_RED = { fill: { color: 0xC40056, opacity: 1 }, stroke: { color: 0xB11C50, opacity: 1 } };
const STUDY_ORANGE = { fill: { color: 0xF55D3E, opacity: 1 }, stroke: { color: 0xF55D3E, opacity: 1 } };
const DARK_GREY = { fill: { color: 0x181A1B, opacity: 1 }, stroke: { color: 0x181A1B, opacity: 1 } };
const LIGHT_GLOW = { fill: { color: 0xE6F8FF, opacity: 1 }, stroke: { color: 0xE6F8FF, opacity: 1 } };
const SURROUND_BLUE = { fill: { color: 0x0173B9, opacity: 1 }, stroke: { color: 0x0173B9, opacity: 1 } };
const GREEN = { fill: { color: 0x51820D, opacity: 1 }, stroke: { color: 0x51820D, opacity: 1 } };
const DARK_MAGENTA = { fill: { color: 0x820263, opacity: 1 }, stroke: { color: 0x820263, opacity: 1 } };
const STUDY_LIGHT_GREY = { fill: { color: 0xD1D5DB, opacity: 1 }, stroke: { color: 0x737E87, opacity: 1 } };
const STUDY_MEDIUM_GREY = { fill: { color: 0xA8ACB2, opacity: 1 }, stroke: { color: 0x8A9299, opacity: 0.5 } };
const SURROUND_MEDIUM_GREY = { fill: { color: 0xA8ACB2, opacity: 1 }, stroke: { color: 0x737E87, opacity: 1 } };
const SURROUND_DARK_GREY = { fill: { color: 0x535D66, opacity: 1 }, stroke: { color: 0x8A9299, opacity: 1 } };
const BACKGROUND_LIGHT_GREY = { fill: { color: 0xDEDEDE, opacity: 1 }, stroke: { color: 0xDEDEDE, opacity: 1 } };
const BACKGROUND_DARK_GREY = { fill: { color: 0x737E87, opacity: 1 }, stroke: { color: 0x737E87, opacity: 1 } };
const TRANSLUCENT_MEDIUM_GREY = { fill: { color: 0x8A9299, opacity: 0.5 }, stroke: { color: 0x8A9299, opacity: 0.25 } };
const TRANSLUCENT_LIGHT_GREY = { fill: { color: 0xD1D5DB, opacity: 0.5 }, stroke: { color: 0xD1D5DB, opacity: 0.25 } };
const TRANSLUCENT_VEGETATION_GREEN = { fill: { color: 0x51820D, opacity: 0.5 }, stroke: { color: 0x51820D, opacity: 0.25 } };
const TRANSLUCENT_MITIGATION_MAGENTA = { fill: { color: 0x820263, opacity: 0.5 }, stroke: { color: 0x820263, opacity: 0.25 } };
const BACKGROUND_WHITE = { fill: { color: 0xFFFFFF, opacity: 1 }, stroke: { color: 0xFFFFFF, opacity: 1 } };
const STUDY_DARK_GREY = { fill: { color: 0x999999, opacity: 1 }, stroke: { color: 0x999999, opacity: 1 } };
const STUDY_GLOW = { fill: { color: 0x51D0FF, opacity: 0.1 }, stroke: { color: 0x51D0FF, opacity: 1 } };
const SURROUND_GLOW = { fill: { color: 0x51D0FF, opacity: 0.05 }, stroke: { color: 0x51D0FF, opacity: 0.2 } };
const LIGHT_GROUND_GREY = { fill: { color: 0xB5B8BC, opacity: 1 }, stroke: { color: 0xB5B8BC, opacity: 0.75 } };
const DARK_GROUND_GREY = { fill: { color: 0x8A9299, opacity: 1 }, stroke: { color: 0x8A9299, opacity: 0.5 } };
const TRANSLUCENT_GROUND_GREY = { fill: { color: 0xA8ACB2, opacity: 1 }, stroke: { color: 0xA8ACB2, opacity: 0.5 } };
const TRON_GROUND_GREY = { fill: { color: 0x0C1F29, opacity: 1 }, stroke: { color: 0x51D0FF, opacity: 0.1 } };
const SURROUND_VERY_DARK_GREY = { fill: { color: 0x252525, opacity: 1 }, stroke: { color: 0x222222, opacity: 1 } };
const DEFAULT_GROUND_GREY = { fill: { color: 0x777777, opacity: 1 }, stroke: { color: 0x777777, opacity: 0.1 } };
const VEGETATION_GREEN = { fill: { color: 0x51820D, opacity: 1 }, stroke: { color: 0x51820D, opacity: 0.1 } };
const MITIGATION_MAGENTA = { fill: { color: 0x820263, opacity: 1 }, stroke: { color: 0x820263, opacity: 0.1 } };
const PLANE = { fill: { color: 0x51D0FF, opacity: 0.05 }, stroke: { color: 0x51D0FF, opacity: 0.2 } };
const PLANE_25 = { fill: { color: 0x51D0FF, opacity: 0.25 }, stroke: { color: 0x51D0FF, opacity: 1 } };
const PLANE_HOT_PINK_25 = { fill: { color: 0xFF69B4, opacity: 0.25 }, stroke: { color: 0x51D0FF, opacity: 1 } };
const PLANE_TRANSPARENT_WHITE_70 = { fill: { color: 0xD1D5DB, opacity: 0.7 }, stroke: { color: 0x51D0FF, opacity: 1 } };
const SURROUND_MEDIUM_BLUE = { fill: { color: 0x005488, opacity: 1 }, stroke: { color: 0x005488, opacity: 1 } };

export default {
  STUDY_YELLOW,
  STUDY_RED,
  STUDY_ORANGE,
  DARK_GREY,
  SURROUND_BLUE,
  GREEN,
  DARK_MAGENTA,
  STUDY_LIGHT_GREY,
  STUDY_MEDIUM_GREY,
  SURROUND_MEDIUM_GREY,
  SURROUND_DARK_GREY,
  BACKGROUND_LIGHT_GREY,
  BACKGROUND_DARK_GREY,
  TRANSLUCENT_MEDIUM_GREY,
  TRANSLUCENT_LIGHT_GREY,
  TRANSLUCENT_VEGETATION_GREEN,
  TRANSLUCENT_MITIGATION_MAGENTA,
  BACKGROUND_WHITE,
  STUDY_DARK_GREY,
  STUDY_GLOW,
  SURROUND_GLOW,
  LIGHT_GROUND_GREY,
  DARK_GROUND_GREY,
  TRANSLUCENT_GROUND_GREY,
  TRON_GROUND_GREY,
  SURROUND_VERY_DARK_GREY,
  DEFAULT_GROUND_GREY,
  VEGETATION_GREEN,
  MITIGATION_MAGENTA,
  PLANE,
  PLANE_25,
  PLANE_HOT_PINK_25,
  SURROUND_MEDIUM_BLUE,
  PLANE_TRANSPARENT_WHITE_70,
  LIGHT_GLOW
};