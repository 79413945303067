import Vue from 'vue';
import * as DOMPurify from 'dompurify';

/**
 * Sanitizes the DOM, replacing most common XSS attack vectors.
 * The directive uses DOMPurify, the recommend OWASP tool to sanitize agains XSS.
 *
 * Notes:
 * 
 * 1. We should update DOMPurify often since new vectors may be discovered.
 * 
 * 2. It only makes sense to use this directive if we are rendering HTML from the back-end
 * (in Vue 2 we would probably use v-html for that).
 *
 * 3. This directive may completely remove elements from the DOM, like <src>.
 *
 * 4. If used with v-ahref-normalized or other DOM modifing directives, and assuming that 
 * the changes they perform on DOM are safe, you must place the v-xss-sanitized before it,
 * otherwise the elements added by v-ahref-normalized may be removed by v-xss-sanitized.
 * 
 * References:
 * 
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html
 * 
 * https://github.com/cure53/DOMPurify
 */
export default {
  install: function() {
    Vue.directive('xss-sanitized', {
      inserted: function(el) {
        el.innerHTML = DOMPurify.sanitize(el.innerHTML);
      }
    });
  }
};