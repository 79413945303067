// @ts-ignore
import router from '@/router';
// @ts-ignore
import store from '@/store';

// @ts-ignore
import { AUTH_LOGOUT } from '@/store/actions/auth';
// @ts-ignore
import { TIMEOUT_ERROR } from '@/store/actions/response';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { AXIOS_DISCONNECTED_FROM_SERVER } from './axios-constants';

// See https://github.com/Flyrell/axios-auth-refresh#request-interceptor
// Obtain the fresh token each time the function is called
function getAccessToken() {
  try {
    return JSON.parse(localStorage.getItem('auth_response') ?? '').access;
  } catch (error) {
    return null;
  }
}

function isTimeoutError(error: AxiosError) {
  return error.code === AXIOS_DISCONNECTED_FROM_SERVER || error.response?.status === 408;
}

async function logout() {
  await store.dispatch(AUTH_LOGOUT);
  router.go({ name: 'LoginPage' });
}

export function addAccessTokenToRequest(request: AxiosRequestConfig) {
  const token = getAccessToken();
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
}

export async function refreshAuthLogic(failedRequest: any) {
  let auth_response;
  try {
    auth_response = JSON.parse(localStorage.getItem('auth_response') ?? '');
  } catch (error) {
    await logout();
  }
  let token_refresh_response;
  try {
    token_refresh_response = await (
      await fetch(`${process.env.VUE_APP_API_BASE_URL}/authjwt/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(auth_response),
      })
    ).json();
    if (!token_refresh_response.access) {
      throw 'Invalid refresh token.';
    }
  } catch (error) {
    await logout();
  }
  auth_response.access = token_refresh_response.access;
  localStorage.setItem('auth_response', JSON.stringify(auth_response));
  failedRequest.response.config.headers[
    'Authorization'
  ] = `Bearer ${auth_response.access}`;
}

export async function handleTimeoutError(error: AxiosError) {
  if (isTimeoutError(error)) {
    await store.dispatch(TIMEOUT_ERROR, error);
  }
  return Promise.reject(error);
}