// https://github.com/Flyrell/axios-auth-refresh#readme
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { DEFAULT_TIMEOUT_MILLISECONDS, AXIOS_AUTH_REFRESH_OPTIONS } from './axios-constants';
import { refreshAuthLogic, addAccessTokenToRequest, handleTimeoutError } from './axios-configuration';
import { AxiosInstance } from 'axios';

export function setupAxiosDefaultsAndInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  axiosInstance.defaults.timeout = DEFAULT_TIMEOUT_MILLISECONDS;

  // See https://github.com/Flyrell/axios-auth-refresh#available-options
  createAuthRefreshInterceptor(
    axiosInstance,
    refreshAuthLogic,
    AXIOS_AUTH_REFRESH_OPTIONS
  );

  axiosInstance.interceptors.request.use((request) => addAccessTokenToRequest(request));
  axiosInstance.interceptors.response.use(response => response, async error => handleTimeoutError(error));

  return axiosInstance;
}