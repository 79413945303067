import projectApiService from '@/network/project';
import { LayerSet } from '@/models/layer-set';
import { EventBus } from '@/network/eventbus';
import { int } from '@kitware/vtk.js/types';
import { filter } from 'lodash';

/**
 * Module that represents the set of layers that the current user
 * has selected on the layer picker.
 *
 * It allows to encapsulate the operations
 * like add, remove, filter and sync with the back-end.
 *
 * Note that SelectedLayers interfaces are specific for the primary/secondary layer panels,
 * given by viewId attribute.
 * 
 * It also addresses how the user selected layers integrates with the default layers (set
 * on admin): default layers will will be displayed together with the user selected layers and both
 * won't be mixed on the back-end. Note thar we may have very specific edge cases here, e.g.
 * layer is set as default after the user has selected it., in this case we update the back-end but
 * the user will see the layer again when s/he refreshes the page.
 */

interface SelectedLayer {
  id: number,
  simulation: number,
  user: number,
  identifier: any;
  view_id: number; // Primary (1), Secondary(2) layer panel
}
class UserSelectedLayers {
  private userSelectedLayers: {[key: number]: SelectedLayer[]};
  private projectId: number;
  private studyId: number
  
  constructor(projectId: number, studyId: number) {
    this.userSelectedLayers = {};
    this.projectId = projectId;
    this.studyId = studyId;
  }

  /**
   * Loads the user's selected layers and group them by simulation.
   */
  async load() {
    let _userLayers = null;
    try {
      _userLayers = await projectApiService.getUserSelectedLayers(
        this.projectId, this.studyId);
    } catch(error) {
      EventBus.$emit('TOAST', {
        variant: 'danger',
        content: 'Failed to fetch the selected layers'});
      this.userSelectedLayers = {};
      throw error;
    }

    this.userSelectedLayers = _userLayers.reduce(function(groupedLayers: any, userLayer: any) {
      groupedLayers[userLayer.simulation] = groupedLayers[userLayer.simulation] || [];
      groupedLayers[userLayer.simulation].push(userLayer);
      return groupedLayers;
    }, {});
  }
  /**
   * Removes all layers from the simulation's selected layer list.
   */
  async removeAll() {
    try {
      await projectApiService.deleteUserSelectedLayers(
        this.projectId,
        this.studyId);
    } catch(error) {
      EventBus.$emit('TOAST', {
        variant: 'danger',
        content: 'Failed to delete all layers'});
      this.userSelectedLayers = {};
      throw error;
    }
  }

  /**
   * Remove a layer from the simulation's selected layer list.
   */
  async remove(simulationId: number, layerToRemove: LayerSet, viewId: number) {
    const simulationUserLayers = this.userSelectedLayers[simulationId];

    const indexUserLayer = simulationUserLayers.findIndex(
      userLayer => this.layerMatches(userLayer, layerToRemove, viewId));

    if (indexUserLayer >= 0) {
      const userLayerToDelete = simulationUserLayers[indexUserLayer];
      try {
        await projectApiService.deleteUserSelectedLayer(
          this.projectId,
          this.studyId,
          userLayerToDelete.id);
      } catch(error) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to remove the layer'});
        this.userSelectedLayers = {};
        throw error;
      }
      simulationUserLayers.splice(indexUserLayer, 1);
    }
  }

  /**
   * Replace a list of user's selected layers for a simulation.
   * // THIS NOTE DOES NOT APPLY ANYMORE - JULY 1st 2024 // Note that we won't append layers set as default. 
   * // NEW NOTE - JULY 1st 2024 // We DO need to append the default ones. because one or more of them
   * might be between the selected ones as well.
   */
  async replace(simulationId: number, newUserSelectedLayers: LayerSet[], viewId: number) {
    const updatedUserLayers = newUserSelectedLayers
      .map(layer => ({simulation: simulationId, identifier: layer.identifier, view_id: viewId}));

    try {
      this.userSelectedLayers[simulationId] = await projectApiService.replaceUserSelectedLayers(
        this.projectId,
        this.studyId,
        {view_id: viewId, userLayers: updatedUserLayers});
    } catch(error) {
      EventBus.$emit('TOAST', {
        variant: 'danger',
        content: 'Failed to update the selected layers list'});
      throw error;
    }      
  }

  /**
   * Given a list of layers, return only the layers that was selected by the current user
   * for a specific simulation or if the layer was set as default.
   */
  filterUserSelectedLayers(simulationId: number, layers: LayerSet[], viewId: number) {
    let simulationUserLayers = this.userSelectedLayers[simulationId];
    if (simulationUserLayers) {
      return layers.filter(layerSet => layerSet.showByDefault === true
        || this.layerSetContainedIn(simulationUserLayers, layerSet, viewId));
    } else {
      return [];
    }
  }

  private layerSetContainedIn(simulationUserLayers: SelectedLayer[], layer: LayerSet, viewId: number) {
    return simulationUserLayers.some(selectedLayer => this.layerMatches(selectedLayer, layer, viewId));
  }

  private layerMatches(selectedLayer: SelectedLayer, layer: LayerSet, viewId: number) {
    return selectedLayer.identifier == layer.identifier
      && selectedLayer.view_id == viewId;
  }
}

export {
  UserSelectedLayers
};