import { Asset } from './asset';
import { FilterGrouping, GlobalFilter } from './filter';

const INVALID_KEYS = ['subsurface_type'];

/**
 *
 * @param asset Asset that will be used to get properties out of it for grouping
 * @param globalFilter Current global filter. The keys of the filter will be traversed to find values in the asset.simulationResult to generate the identifier
 * @returns A stringified object which can be used for grouping assets into LayerSets
 */
function grouper(asset: Asset, globalFilter: GlobalFilter): string {
  return JSON.stringify(createGroupingObject(asset, globalFilter));
}

function createGroupingObject(asset: Asset, globalFilter: GlobalFilter): FilterGrouping {
  return Object
    .keys(globalFilter)
    .reduce((dictionary: FilterGrouping, key: string) => addToDictionary(dictionary, key, asset), {});
}

function addToDictionary(dictionary: FilterGrouping, key: string, asset: Asset): FilterGrouping {
  if (isValidKeyValuePair(asset, key)) {
    dictionary[key] = asset.simulationResult[key];
  }
  return dictionary;
}

function isValidKeyValuePair(asset: Asset, key: string): boolean {
  return !INVALID_KEYS.includes(key) && asset.simulationResult[key] != null;
}

export {
  grouper
};