import { ContainerClient } from '@azure/storage-blob';
import store from '@/store';
export default async function uploadToBlobStorage(file, progress, error) {
  let assetUploadURL = store.getters['project/assetUploadUrl'];
  try {

    const containerClient = new ContainerClient(assetUploadURL);
    const filename = `${file.name}`;
    const blobBlockClient = containerClient.getBlockBlobClient(file.name);  

    //fake a progress bar.  Need to refactor the way we upload to blob storage to get actual progress updates.
    //This at least shows that an upload is in progress, which is important when adding a new MET custom met file or adding a project image
    progress(10);
    let finished=false;
    setTimeout(() => {
      if (!finished) {
        progress(30);
        setTimeout(() => {
          if (!finished) {
            progress(60);
            setTimeout(() => {
              if (!finished) {
                progress(80);
              }
            }, 3000);
          }
        }, 2000);
      }
    }, 1000);

    blobBlockClient.upload(file, file.size).then(
      () => {
        finished = true;
        progress(100);
      },
      (error) => {
        finished = true;
        error(`Unable to upload ${file.name}`);
        console.error(error);
      }
    );
    return Promise.resolve({
      file: filename,
      path: `${store.getters['project/uploadUuid']}/${filename}`,
      url: `${store.getters['project/containerUrl']}${store.getters['project/uploadUuid']}/${filename}?${store.getters['project/sasToken']}`,
      name: filename
    });
  } catch (ex) {
    error(`Unable to upload ${file.name}`);
    console.error(ex);
    console.log(`assetUploadURL is: ${assetUploadURL}`);
  }
}