export const ADD_PROJECT = 'projects.add_project';
export const EDIT_ALL_COMPANY_PROJECTS = 'projects.edit-all-company-projects';
export const VIEW_PROJECT = 'projects.view_project';
export const CHANGE_PROJECT = 'projects.change_project';
export const DELETE_PROJECT = 'projects.delete_project';
export const RE_SHARE_PROJECT = 'projects.re-share-project';
export const FETCH_MET_MANUALLY = 'projects.fetch-MET-manually';
export const ADD_SCENARIO = 'simulation_assets.add_configuration';
export const EDIT_SCENARIO = 'simulation_assets.change_configuration';
export const RUN_ML_SIM = 'simulation_assets.can_run_ml_simulation';
export const CHANGE_USER = 'custom_auth.change_user';
export const CHANGE_SHARE_GROUP = 'custom_auth.change_sharegroup';
export const AUTHOR_INSIGHTS = 'projects.add_insight';