export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PROJECT_PERMISSIONS = 'GET_PROJECT_PERMISSIONS';
export const PERMISSIONS_RESET = 'PERMISSIONS_RESET';
export const GET_EULA = 'GET_EULA';
export const SUBMIT_ACCEPTED_EULA = 'SUBMIT_ACCEPTED_EULA';
export const GET_USER_COMPANY = 'GET_USER_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_COMPANY_USERS_TO_ADD = 'GET_COMPANY_USERS_TO_ADD';
export const CREATE_COMPANY_USER = 'CREATE_COMPANY_USER';
export const CREATE_COMPANY_USER_ERROR = 'CREATE_COMPANY_USER_ERROR';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';
export const DELETE_COMPANY_USER_ERROR = 'DELETE_COMPANY_USER_ERROR';
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER';
export const UPDATE_COMPANY_USER_ERROR = 'UPDATE_COMPANY_USER_ERROR';
export const GET_COMPANY_ROLES = 'GET_COMPANY_ROLES';
export const GET_COMPANY_SHARE_GROUPS = 'GET_COMPANY_SHARE_GROUPS';
export const CREATE_COMPANY_SHARE_GROUP = 'CREATE_COMPANY_SHARE_GROUP';
export const CREATE_COMPANY_SHARE_GROUP_ERROR = 'CREATE_COMPANY_SHARE_GROUP_ERROR';
export const DELETE_COMPANY_SHARE_GROUP = 'DELETE_COMPANY_SHARE_GROUP';
export const DELETE_COMPANY_SHARE_GROUP_ERROR = 'DELETE_COMPANY_SHARE_GROUP_ERROR';
export const UPDATE_COMPANY_SHARE_GROUP = 'UPDATE_COMPANY_SHARE_GROUP';
export const UPDATE_COMPANY_SHARE_GROUP_ERROR = 'UPDATE_COMPANY_SHARE_GROUP_ERROR';
export const GET_COMPANY_SHARE_GROUP_USERS = 'GET_COMPANY_SHARE_GROUP_USERS';
export const ADD_COMPANY_SHARE_GROUP_USERS = 'ADD_COMPANY_SHARE_GROUP_USERS';
export const ADD_COMPANY_SHARE_GROUP_USERS_ERROR = 'ADD_COMPANY_SHARE_GROUP_USERS_ERROR';
export const REMOVE_COMPANY_SHARE_GROUP_USER = 'DELETE_COMPANY_SHARE_GROUP_USER';
export const REMOVE_COMPANY_SHARE_GROUP_USER_ERROR = 'REMOVE_COMPANY_SHARE_GROUP_USER_ERROR';
export const GET_COMPANY_SUBSCRIPTIONS = 'GET_COMPANY_SUBSCRIPTIONS';
export const GET_COMPANY_AVAILABLE_SUBSCRIPTIONS = 'GET_COMPANY_AVAILABLE_SUBSCRIPTIONS';
export const GET_COMPANY_SUBSCRIPTION_USAGE = 'GET_COMPANY_SUBSCRIPTION_USAGE';
export const GET_COMPANY_SUBSCRIPTION_TYPE_PRICES = 'GET_COMPANY_SUBSCRIPTION_TYPE_PRICES';
export const GET_SIMULATION_PRICES = 'GET_SIMULATION_PRICES';
export const GET_SCENARIO_COST_BREAKDOWN = 'GET_SCENARIO_COST_BREAKDOWN';
export const GET_USER_PENDING_PROJECT_SHARES = 'GET_USER_PENDING_PROJECT_SHARES';
export const GET_USER_PROJECT_SHARES = 'GET_USER_PROJECT_SHARES';
export const SET_OTP_PROJECT_SHARE_ID = 'SET_OTP_PROJECT_SHARE_ID';
export const UPDATE_USER_PROJECT_SHARE = 'UPDATE_USER_PROJECT_SHARE';
export const GET_USER_PENDING_GLOBAL_NOTIFICATIONS = 'GET_USER_PENDING_GLOBAL_NOTIFICATIONS';
export const UPDATE_USER_GLOBAL_NOTIFICATIONS = 'UPDATE_USER_GLOBAL_NOTIFICATIONS';