import { Asset } from './asset';
import { GlobalFilter } from './filter';
import humanizeString from 'humanize-string';
import { grouper } from '@/models/layer-set-grouping';
import { LayerSet } from '@/models/layer-set';
import _ from 'lodash';

class InputOption {
  value: string;
  label: string;
  disabled: boolean;
  style: string;

  constructor(value: string, label: string, disabled: boolean) {
    this.value = value;
    this.label = label;
    this.disabled = disabled;
    this.style = disabled ? 'text-decoration: line-through; color: LightGrey' : '';
  }
}

class Input {
  globalFilterKey: string;
  assets: Asset[];
  globalFilter: GlobalFilter;

  get title(): string {
    return this.globalFilterKey;
  }

  get values(): InputOption[] {
    return this.distinctOptionStrings
      .sort()  //sorts alphabetically.  this doesn't make the most sense for 'season' or 'time' 
      .map(strValue => new InputOption(strValue, this.labelString(strValue), this.numberOfLayersMatchingOption(strValue) <= 0));
  }

  get label(): string {
    return humanizeString(this.globalFilterKey);
  }

  get assetsContainingGlobalFilterKey(): Asset[] {
    return this.assets.filter(asset => asset.simulationResult[this.globalFilterKey] != null);
  }

  get distinctOptionStrings(): string[] {
    return [...new Set(this.assetsContainingGlobalFilterKey.map(asset => asset.simulationResult[this.globalFilterKey]))];
  }

  constructor(globalFilterKey: string, assets: Asset[], globalFilter: GlobalFilter) {
    this.globalFilterKey = globalFilterKey;
    this.assets = assets;
    this.globalFilter = globalFilter;
  }

  numberOfLayersMatchingOption(option: string): number {
    const assets = this.assets.filter(asset => asset.matchesFilterExcludingKey(this.globalFilter, this.globalFilterKey) && asset.simulationResult[this.globalFilterKey] === option);
    const layers = Object
      .entries(_.groupBy(assets, asset => grouper(asset, this.globalFilter)))
      .map(([key, assets]) => new LayerSet(key, assets));
    return layers.length;
  }

  labelString(option: string): string {
    return `${option} (${this.numberOfLayersMatchingOption(option)})`;
  }
}

export {
  Input
};