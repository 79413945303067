export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_OAUTH_REDIRECT = 'AUTH_OAUTH_REDIRECT';
export const AUTH_OAUTH_REQUEST_REDIRECT_URL = 'AUTH_OAUTH_REQUEST_REDIRECT_URL';
export const AUTH_OK = 'AUTH_OK';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_LOGIN_USERNAME_PASSWORD = 'AUTH_LOGIN_USERNAME_PASSWORD';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_CONFIRM_RESET_PASSWORD = 'AUTH_CONFIRM_RESET_PASSWORD';
export const AUTH_QUESTIONNAIRE = 'AUTH_QUESTIONNAIRE';
export const AUTH_QUESTIONNAIRE_ANSWERS = 'AUTH_QUESTIONNAIRE_ANSWERS';
export const RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_CONFIRM';
export const SIGNUP_OK = 'SIGNUP_OK';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const GET_SOCKET_CONNECTION = 'GET_SOCKET_CONNECTION';
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_PROVINCES = 'GET_PROVINCES';
export const LOGIN_WITH_ONE_TIME_PASSWORD = 'LOGIN_WITH_ONE_TIME_PASSWORD';