function getTitle(vm) {
  const { pageTitle } = vm.$options;
  if (pageTitle) {
    return typeof pageTitle === 'function' ? pageTitle.call(vm) : pageTitle;
  }
}

function getEnvironmentString() {
  if (process.env.VUE_APP_ENVIRONMENT === 'local') {
    return '[Local] ';
  }

  if (process.env.VUE_APP_ENVIRONMENT === 'development') {
    return '[Dev] ';
  }

  if (process.env.VUE_APP_ENVIRONMENT === 'staging') {
    return '[Staging] ';
  }

  return null;
}

const titleMixin = {
  mounted() {
    this.updateTitle();
  },
  updated() {
    this.updateTitle();
  },
  methods: {
    updateTitle() {
      const title = getTitle(this);
      if (title) {
        document.title = `${getEnvironmentString() || ''}${title} | OrbitalStack`;
      }
    }
  }
};

export default titleMixin;