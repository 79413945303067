function createRouteObject(route) {
  const routeObject = { ...route, component: () => import(/* webpackChunkName: "[request]" */ '@/components/' + route.componentPath + '.vue') }; // eslint-disable-line prefer-template
  if (!route.children) {
    return routeObject;
  }
  return { ...routeObject, children: route.children.map(childRoute => createRouteObject(childRoute)) };
}

const routes = [
  { name: 'AppContainer', path: '/', componentPath: 'AppContainer', redirect: { name: 'ContentContainer' }, children: [
    { name: 'ContentContainer', path: '', componentPath: 'ContentContainer', redirect: { name: 'ProjectContainer' }, children: [
      { name: 'CompanyAdministration', path: 'company/admin', componentPath: 'permissions/CompanyAdministration', meta: { title: 'Company Administration' } },
      { name: 'ProjectContainer', path: 'projects', componentPath: 'projects/ProjectContainer', redirect: { name: 'ProjectList' }, children: [
        { name: 'ProjectDetail', path: ':id', componentPath: 'projects/detail/ProjectDetail', children: [
          { name: 'ReportContainer', path: 'report/:report/', componentPath: 'projects/detail/ReportContainer' },
          { name: 'properties', path: ':tabName', componentPath: 'projects/ProjectSettingsContainer' },

          { name: 'ViewerContainer', path: 'viewer/:study/configuration/:configuration/:tabName', componentPath: 'projects/detail/ViewerContainer', meta: { containsDropdown: true }},
          { name: 'ViewerContainerNoTabName', path: 'viewer/:study/configuration/:configuration', redirect: to => { 
            return { 
              name: 'ViewerContainer', 
              params: {tabName: 'Viewer', ...to.params}
            };
          }
          },  //backwards compatibility for bookmarked urls where tabname isn't provided
          { name: 'ViewerContainerNoScenarios', path: ':tabName', componentPath: 'projects/detail/ViewerContainer', meta: { containsDropdown: true }},
        ]},
        { name: 'ProjectList', path: '', componentPath: 'projects/list/ProjectList', meta: { title: 'Projects' } },
        { name: 'simulationRedirect', path: ':id/viewer/:study/:simulation', componentPath: 'views/navigation/simulationRedirect'},
        { name: 'NavigatorContainer', path: ':id/wheel', componentPath: 'projects/detail/NavigatorContainer', redirect: { name: 'NavigatorOverview' }, children: [
          { name: 'NavigatorOverview', path: 'navigator', componentPath: 'projects/detail/navigator/Overview' },
          { name: 'NavigatorDetails', path: 'details', componentPath: 'projects/detail/navigator/Details' },
          { name: 'NavigatorSettings', path: 'settings', componentPath: 'projects/detail/navigator/Settings' }
        ]}
      ]},
    ]},
  ]},
  { name: 'OTPEmailPage', path: '/otp-email', componentPath: 'authentication/OTPEmailPage', pathToRegexpOptions: { strict: true }, meta: { requiresAuth: false } },
  { name: 'OTPLoginPage', path: '/otp-login', componentPath: 'authentication/OTPLoginPage', pathToRegexpOptions: { strict: true }, meta: { requiresAuth: false } },
  { name: 'LoginPage', path: '/login/', componentPath: 'authentication/LoginPage', pathToRegexpOptions: { strict: true }, meta: { requiresAuth: false } },
  { name: 'ResetPassword', path: '/reset_password/', componentPath: 'authentication/ResetPassword', meta: { requiresAuth: false } },
  { name: 'ResetPasswordConfirm', path: '/password/reset/confirm/:uid/:token', componentPath: 'authentication/ResetPassword', meta: { requiresAuth: false } },
  { name: 'SignupPage', path: '/signup', componentPath: 'authentication/SignupPage', meta: { requiresAuth: false }},
  { path: '*', redirect: { name: 'LoginPage' }}
].map(route => createRouteObject(route));

export default routes;