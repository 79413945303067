import servicelineApiService from '@/network/serviceline';

function initialState() {
  return {
    serviceLines: null,
    error: null
  };
}

const state = initialState();

const getters = {
  all(state) {
    if (!state.serviceLines) {
      return [];
    }
    return state.serviceLines;
  }
};

const actions = {
  getAllServiceLines({ commit }) {
    return servicelineApiService.getAllServiceLines().then(response => {
      commit('setServiceLines', response);
    }).catch(error => {
      commit('setError', error);
    });
  },
  reset({ commit }) {
    commit('resetState');
  }
};

const mutations = {
  setServiceLines(state, data) {
    state.serviceLines = data;
  },
  setError(state, error) {
    state.error = error;
  },
  resetState(state) {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};