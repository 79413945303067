import _ from 'lodash';

export default {


  append_calculated_properties(asset) {
    asset = _.cloneDeep(asset);  //cloning is required here or each appneded property causes getters in the simulationAsset store to fire and in some circumstances an infinite loop can occur

    /*
    1) Calculation exists on the asset but Dataset doesn't -> map 'Calculation' to 'Metric'
    2) Dataset exists on the asset but Calculation doesn't -> map 'Dataset ' to 'Metric'
    2) Calculation and Dataset both exists on the asset -> map 'Dataset ' to 'Metric' and ignore 'Calculation'
    */
    if (!Object.prototype.hasOwnProperty.call(asset, 'metric')) {
      if (Object.prototype.hasOwnProperty.call(asset, 'calculation')) {
        asset.metric = asset.calculation;
      }
      if (Object.prototype.hasOwnProperty.call(asset, 'dataset')) {
        asset.metric = asset.dataset;
      }
    }

    //append properties to the simluationResult to flatten contained objects into a string so they can be represented easily via Vue Formulate
    if (Object.prototype.hasOwnProperty.call(asset, 'qualifiers')) {
      //create a generic "qualifiers" property that can be filtered by and append all possible options.  This means that we don't need to list out each possible qualifier in asset_constants.LEYS_TO_FILTER_BY
      asset.qualifierList = [];
      Object.keys(asset.qualifiers).forEach(propertyName => {
        if (!asset.qualifierList.some((qualifier) => qualifier === asset.qualifiers[propertyName])) {
          asset.qualifierList.push(asset.qualifiers[propertyName]);
        }
      });
      asset.qualifier = asset.qualifierList.join(' · ');  //join all the qualifiers into a string.
    }

    if (!Object.prototype.hasOwnProperty.call(asset, 'season')) {
      //The "season" is string representation of the season and time combined.

      if (Object.prototype.hasOwnProperty.call(asset, 'season_range')) {
        //if there is no "season" property but there is a "season_range" property, then build a "season_filter" property that we can filter by using the values from "season_range"
        asset.season_filter = asset.season_range.name;
      }

      if(Object.prototype.hasOwnProperty.call(asset, 'time_range')) {
        //if there is a "time_range" property, then build a "time_filter" property that we can filter by using the values from "time_range"
        
        if (asset.time_range.name?.toLowerCase() === 'custom') {
          //special case for custom time ranges.  it makes no sense to display them as "custom" when we could just abbreviate the range
          asset.time_filter = `${asset.time_range.start_time}-${asset.time_range.end_time}`;
        } else {
          asset.time_filter = asset.time_range.name;
        }
        
        
      }
    } else {
      // If there is a "season" property, built two separate "season_filter" and "time_filter" properties
      let season_time = `${asset.season}`;
      const season_time_array = season_time.split(' ');
      asset.season_filter = season_time_array[0];
      asset.time_filter = season_time_array[1];
    }

    return asset;
  }
};