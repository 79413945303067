import axios from 'axios';

function queryParamsFromPermissions(permissions) {
  return new URLSearchParams(permissions.map(permission => ['user_permission', permission]));
}

function queryParamsFromProjectId(projectId) {
  return new URLSearchParams(['project', projectId]);
}

export default {
  async getPermissions(permissions) {
    const response = await axios.get('api/permissions/', { params: queryParamsFromPermissions(permissions) });
    return response.data;
  },
  async getProjectPermissions(projectId, permissions) {
    const response = await axios.get('api/project-permissions/', { project: queryParamsFromProjectId(projectId), params: queryParamsFromPermissions(permissions) });
    return response.data;
  },
  async getEula() {
    const response = await axios.get('api/eula/');
    return response.data;
  },
  async submitEula(eula_id) {
    const response = await axios.post(`api/eula/${eula_id}/agreement`);
    return response.data;
  },
  async getCompanyById(companyId) {
    const response = await axios.get(`api/company/id/${companyId}`);
    return response.data;
  },
  async getCompany() {
    const response = await axios.get('api/company');
    return response.data;
  },
  async getCompanyUsers(page) {
    const response = await axios.get(`api/company/users?page=${page}`);
    return response.data;
  },
  async createCompanyUser(companyUserPayload) {
    const response = await axios.post('api/company/users', companyUserPayload);
    return response.data;
  },
  async deleteCompanyUser(companyUserId) {
    const response = await axios.delete(`api/company/users/${companyUserId}`);
    return response.data;
  },
  async updateCompanyUser(companyUserId, companyUserPayload) {
    const response = await axios.patch(`api/company/users/${companyUserId}`, companyUserPayload);
    return response.data;
  },
  async getCompanyRoles() {
    const response = await axios.get('api/roles/');
    return response.data;
  },
  async getCompanyShareGroups() {
    const response = await axios.get('api/company/share-groups');
    return response.data;
  },
  async createCompanyShareGroup(companyShareGroupPayload) {
    const response = await axios.post('api/company/share-groups', companyShareGroupPayload);
    return response.data;
  },
  async deleteCompanyShareGroup(companyShareGroupId) {
    const response = await axios.delete(`api/company/share-groups/${companyShareGroupId}`);
    return response.data;
  },
  async updateCompanyShareGroup(companyShareGroupId, companyShareGroupPayload) {
    const response = await axios.patch(`api/company/share-groups/${companyShareGroupId}`, companyShareGroupPayload);
    return response.data;
  },
  async getCompanyShareGroupUsers(companyShareGroupId) {
    const response = await axios.get(`api/company/share-groups/${companyShareGroupId}/users`);
    return response.data;
  },
  async addCompanyShareGroupUsers(companyShareGroupId, companyShareGroupUserIds) {
    const response = await axios.post(`api/company/share-groups/${companyShareGroupId}/users`, companyShareGroupUserIds);
    return response.data;
  },
  async removeCompanyShareGroupUser(companyShareGroupId, companyShareGroupUserId) {
    const response = await axios.delete(`api/company/share-groups/${companyShareGroupId}/users/${companyShareGroupUserId}`);
    return response.data;
  },
  async getCompanySubscriptions() {
    const response = await axios.get('api/company/subscriptions');
    return response.data;
  },
  async getCompanyAvailableSubscriptions() {
    const response = await axios.get('api/company/available-subscriptions');
    return response.data;
  },
  async getCompanySubscriptionUsage(page, orderBy) {
    const response = await axios.get(`api/company/subscription-usage?page=${page}&order_by=${orderBy}`);
    return response.data;
  },
  async getCompanySubscriptionTypePrices() {
    const response = await axios.get('api/company/subscription-type-prices');
    return response.data;
  },
  async getSimulationPrices() {
    const response = await axios.get('api/simulation-prices');
    return response.data;
  },
  async getScenarioCostBreakdown(projectId, studyId, configurationId) {
    const response = await axios.get(`api/project/${projectId}/study/${studyId}/configuration/${configurationId}/user-approval/cost-breakdown/`);
    return response.data;
  },
  async getUserPendingProjectShares() {
    const response = await axios.get('api/user/pending-project-shares');
    return response.data;
  },
  async getUserPendingGlobalNotifications() {
    const response = await axios.get('api/user/notifications');
    return response.data;
  },
  async updateUserProjectShare(projectShareId, projectSharePayload) {
    const response = await axios.post(`api/user/project-shares/${projectShareId}`, projectSharePayload);
    return response.data;
  },
  async updateUserNotication(notificationId) {
    const response = await axios.post(`api/user/dismiss-notification/${notificationId}`);
    return response.data;
  },
  async getUserProjectShares() {
    const response = await axios.get('api/user/project-shares');
    return response.data;
  },
};
