import axios from 'axios';

export default {
  async validateGeometry(
    scenario_id: number, 
    simulation_id: number, 
    ground: string[], 
    study: string[], 
    surrounds: string[], 
    presentation_planes: string[], 
    landscaping: string[], 
    mitigations: string[],
    simulation_type: string ){


    let validationRequest = {
      'scenario_id': scenario_id, 
      'simulation_id': simulation_id,
      'simulation_type': simulation_type,
      'geometry': {
        'ground': ground,
        'study': study,
        'surrounds': surrounds,
        'presentation_planes': presentation_planes,
        'landscaping': landscaping,
        'mitigations': mitigations
      }
    };
    const url = String(process.env.VUE_APP_GEOMETRY_VALIDATION_URL);
    const response = await axios.post(url, validationRequest);
    return response.data;
  }
};
