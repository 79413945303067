import {
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_OAUTH_REDIRECT,
  AUTH_OAUTH_REQUEST_REDIRECT_URL,
  AUTH_OK,
  AUTH_REQUEST,
  AUTH_LOGIN_USERNAME_PASSWORD,
  AUTH_SIGNUP,
  AUTH_RESET_PASSWORD,
  AUTH_CONFIRM_RESET_PASSWORD,
  AUTH_QUESTIONNAIRE,
  AUTH_QUESTIONNAIRE_ANSWERS,
  RESET_PASSWORD_ERROR,
  SIGNUP_OK,
  SIGNUP_ERROR,
  GET_SOCKET_CONNECTION,
  GET_LOGGED_IN_USER,
  GET_COUNTRIES,
  GET_PROVINCES,
  LOGIN_WITH_ONE_TIME_PASSWORD
} from '@/store/actions/auth';
import {
  AUTHENTICATED,
  ERROR,
  LOGGED_OUT,
  WAIT_FOR_OAUTH_REDIRECT_URL,
  WAIT_FOR_OAUTH_RESPONSE,
  WAIT_FOR_TOKEN,
  SIGNUP_SUCCESS
} from '@/store/statuses/auth';
import authenticationApiService from '@/network/authentication';
import { PERMISSIONS_RESET } from '@/store/actions/permissions';

const url = `${process.env.VUE_APP_API_BASE_URL}/auth/o/azuread-tenant-oauth2/`;

const state = {
  status: localStorage.getItem('status') || LOGGED_OUT,
  auth_response: JSON.parse(localStorage.getItem('auth_response')) || null,
  oauth_state: localStorage.getItem('oauth_state') || null,
  redirect_uri: localStorage.getItem('redirect_uri') || null,
  isQuestionnaireOn: localStorage.getItem('questionnaireOn') || null,
  reset_password: null,
  signup_error: null,
  webSocketConnectionInfo: null,  //  {'group': organization_name, 'clientAccessUrl': clientAccessToken.url }
  loggedInUser: null,
  countries: [],
  provinces: []
};

const getters = {
  authState: state => state,
  loggedInUser: state => state.loggedInUser,
  countries: state => state.countries,
  provinces: state => state.provinces,
  isQuestionnaireOn: state => state.isQuestionnaireOn,
  userHasSubscription: state => Number.isInteger(state.loggedInUser.subscription),
  webSocketConnectionInfo(state) {
    return state.webSocketConnectionInfo;
  }
};

async function login(url, state, redirect_uri) {
  const search_params = `${location.search.substring(1)}&redirect_uri=${redirect_uri}&azuread-tenant-oauth2_state=${state}`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: new URLSearchParams(search_params),
  });
  return await response.json();
}

function jsonAndBase64Encode(object) {
  return btoa(JSON.stringify(object));
}

function base64AndJsonDecode(string) {
  return JSON.parse(atob(string));
}

const actions = {
  [AUTH_OAUTH_REQUEST_REDIRECT_URL]: async ({ commit, dispatch }, { auth_redirect_uri, original_path }) => {
    await commit(AUTH_OAUTH_REQUEST_REDIRECT_URL);
    // Request Azure Active Directory OAuth2 redirect URL.
    const auth_url_response = await fetch(`${url}?redirect_uri=${auth_redirect_uri}`);
    const auth_url = (await auth_url_response.json()).authorization_url;

    // Extract OAuth state and redirect_uri from URL.
    const query_params = Object.fromEntries(new URLSearchParams(auth_url.split('?')[1]));
    const oauth_state = jsonAndBase64Encode({
      state: query_params['state'],
      original_path: original_path
    });
    const redirect_uri = query_params['redirect_uri'];

    await dispatch(AUTH_OAUTH_REDIRECT, {auth_url, oauth_state, redirect_uri});
  },
  [AUTH_OAUTH_REDIRECT]: async ({ commit }, {auth_url, oauth_state, redirect_uri}) => {
    await commit(AUTH_OAUTH_REDIRECT, {oauth_state, redirect_uri});
    // Redirect to third-party social auth.
    document.location = auth_url;
  },
  [AUTH_REQUEST]: async ({ state, commit, dispatch }, query_params) => {
    // post OAuth2 response to backend API.
    const redirect_uri = state.redirect_uri;
    await commit(AUTH_REQUEST);
    const expected_oauth_state = query_params.state;
    if (!expected_oauth_state || base64AndJsonDecode(state.oauth_state).state !== expected_oauth_state) {
      await dispatch(AUTH_ERROR);
      return null;
    }
    try {
      const auth_response = await login(url, base64AndJsonDecode(state.oauth_state).state, redirect_uri);
      await dispatch(AUTH_OK, auth_response);
      return base64AndJsonDecode(state.oauth_state).original_path;
    } catch(error) {
      console.log(error);
      commit(AUTH_ERROR);
    }
  },
  [AUTH_OK]: async ({ commit, dispatch }, auth_response) => {
    await dispatch(PERMISSIONS_RESET);
    await commit(AUTH_OK, auth_response);
  },
  [AUTH_LOGOUT]: async ({ commit }) => {
    await commit(AUTH_LOGOUT);
  },
  [AUTH_ERROR]: async ({ commit }) => {
    await commit(AUTH_ERROR);
  },
  [AUTH_LOGIN_USERNAME_PASSWORD]: async ({ dispatch }, { username, password }) => {
    try {
      const response = await authenticationApiService.login(username, password);
      await dispatch(AUTH_OK, response);
    } catch (error) {
      await dispatch(AUTH_ERROR);
    }
  },
  [AUTH_SIGNUP]: async ({ commit }, userData) => {
    try {
      await authenticationApiService.signup(userData);
      await commit(SIGNUP_OK);
    } catch(error) {
      await commit(SIGNUP_ERROR, error.response.data);
    }
  },
  [AUTH_RESET_PASSWORD]: async ({ dispatch }, { email }) => {
    try {
      // clear any password reset errors
      await dispatch(RESET_PASSWORD_ERROR, null);
      await authenticationApiService.sendPasswordResetEmail(email);
    } catch (error) {
      await dispatch(RESET_PASSWORD_ERROR, ERROR);
    }
  },
  [AUTH_CONFIRM_RESET_PASSWORD]: async ({ dispatch }, resetPasswordPayload) => {
    try {
      // clear any password reset errors
      await dispatch(RESET_PASSWORD_ERROR, null);
      await authenticationApiService.resetPassword(resetPasswordPayload);
    } catch (error) {
      await dispatch(RESET_PASSWORD_ERROR, ERROR);
    }
  },
  [AUTH_QUESTIONNAIRE_ANSWERS]: async ({ commit }, answers) => {
    try {
      await authenticationApiService.questionnaireAnswers(answers);
      await commit(AUTH_QUESTIONNAIRE, false);
    } catch (error) {
      await commit(AUTH_QUESTIONNAIRE, true);
    }
  },
  [AUTH_QUESTIONNAIRE]: async ({ commit }, newStatus) => {
    await commit(AUTH_QUESTIONNAIRE, newStatus);
  },
  [RESET_PASSWORD_ERROR]: async ({ commit }, error) => {
    await commit(RESET_PASSWORD_ERROR, error);
  },
  [GET_SOCKET_CONNECTION]: async ( { commit }) => {
    try {
      const response = await authenticationApiService.getWebsocketClientAccessToken();
      if (response.status == 200) {
        commit(GET_SOCKET_CONNECTION, response.data);
      }
    } catch (error) {
      console.log('failed to connect to websocket.  Live updates will not occur');
    }
  },
  [GET_LOGGED_IN_USER]: async ({ commit }) => {
    try {
      const loggedInUser = await authenticationApiService.getLoggedInUser();
      commit(GET_LOGGED_IN_USER, loggedInUser);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COUNTRIES]: async ({ commit }) => {
    try {
      const countries = await authenticationApiService.getCountries();
      commit(GET_COUNTRIES, countries);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_PROVINCES]: async ({ commit }) => {
    try {
      const provinces = await authenticationApiService.getProvinces();
      commit(GET_PROVINCES, provinces);
    } catch(error) {
      console.error(error);
    }
  },
  [LOGIN_WITH_ONE_TIME_PASSWORD]: async ({ dispatch }, payload) => {
    try {
      const auth_response = await authenticationApiService.loginWithOneTimePassword(payload);
      await dispatch(AUTH_OK, auth_response);
    } catch(error) {
      await dispatch(AUTH_ERROR);
    }
  }
};

const mutations = {
  [AUTH_OAUTH_REQUEST_REDIRECT_URL]: async (state) => {
    state.status = WAIT_FOR_OAUTH_REDIRECT_URL;
    localStorage.setItem('status', state.status);
  },
  [AUTH_OAUTH_REDIRECT]: async (state, {oauth_state, redirect_uri}) => {
    state.status = WAIT_FOR_OAUTH_RESPONSE;
    localStorage.setItem('status', state.status);
    state.redirect_uri = redirect_uri;
    localStorage.setItem('redirect_uri', state.redirect_uri);
    state.oauth_state = oauth_state;
    localStorage.setItem('oauth_state', oauth_state);
  },
  [AUTH_REQUEST]: async (state) => {
    state.status = WAIT_FOR_TOKEN;
    localStorage.setItem('status', state.status);
    state.redirect_uri = null;
    localStorage.removeItem('redirect_uri');
  },
  [AUTH_OK]: async (state, auth_response) => {
    state.status = AUTHENTICATED;
    localStorage.setItem('status', state.status);
    state.auth_response = auth_response;
    localStorage.setItem('auth_response', JSON.stringify(auth_response));
    state.state = null;
    state.redirect_uri = null;
    localStorage.removeItem('oauth_state');
    localStorage.removeItem('redirect_uri');
    localStorage.removeItem('otpProjectShareId');
  },
  [AUTH_ERROR]: state => {
    state.status = ERROR;
    localStorage.setItem('status', state.status);
  },
  [AUTH_LOGOUT]: state => {
    state.status = LOGGED_OUT;
    localStorage.setItem('status', state.status);
    state.auth_response = null;
    state.state = null;
    state.redirect_uri = null;
    localStorage.removeItem('auth_response');
    localStorage.removeItem('oauth_state');
    localStorage.removeItem('redirect_uri');
    localStorage.removeItem('otpProjectShareId');
  },
  [AUTH_QUESTIONNAIRE]: (state, status) => {
    if (status) {
      state.isQuestionnaireOn = status;
      localStorage.setItem('questionnaireOn', state.isQuestionnaireOn);
    } else {
      state.isQuestionnaireOn = status;
      localStorage.removeItem('questionnaireOn');
    }
  },
  [RESET_PASSWORD_ERROR]: (state, error) => {
    state.reset_password = error;
  },
  [SIGNUP_OK]: (state) => {
    state.status = SIGNUP_SUCCESS;
    localStorage.setItem('status', state.status);
  },
  [SIGNUP_ERROR]: (state, error) => {
    state.status = ERROR;
    state.signup_error = error;
  },
  [GET_SOCKET_CONNECTION]: (state, socketConnectionInfo) => {
    state.webSocketConnectionInfo = socketConnectionInfo;
  },
  [GET_LOGGED_IN_USER]: (state, loggedInUser) => {
    state.loggedInUser = loggedInUser;
  },
  [GET_COUNTRIES]: (state, countries) => {
    state.countries = countries;
  },
  [GET_PROVINCES]: (state, provinces) => {
    state.provinces = provinces;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
