import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from './routes';
import beforeEach from './before-routing';
import startAnalyticsWithRouter from './router-analytics';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => beforeEach(to, from, next));
startAnalyticsWithRouter(router);

export default router;