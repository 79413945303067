import {
  TIMEOUT_ERROR,
  TIMEOUT_RESET
} from '@/store/actions/response';

function initialState() {
  return {
    error: null
  };
}

const state = initialState();

const getters = {
  error: state => state.error
};

const actions = {
  [TIMEOUT_RESET]: async ({ commit }) => {
    await commit(TIMEOUT_RESET);
  },
  [TIMEOUT_ERROR]: async ({ commit }, error) => {
    await commit(TIMEOUT_ERROR, error);
  }
};

const mutations = {
  [TIMEOUT_ERROR]: (state, error) => {
    state.error = error;
  },
  [TIMEOUT_RESET]: state => {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};