import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'leaflet/dist/leaflet.css';

// @ts-ignore
import VueFormulate from '@braid/vue-formulate';

// @ts-ignore
import VTooltip from 'v-tooltip';

// @ts-ignore
import App from './App.vue';
// @ts-ignore
import router from './router';
// @ts-ignore
import store from './store';

import axios from 'axios';
import { setupAxiosDefaultsAndInterceptors } from './network/axios-setup';
// @ts-ignore
import uploadToBlobStorage from './network/upload-blob';
// @ts-ignore
import titleMixin from './mixins/title';

// This modifies the global axios state for future imports
// Any future imports of the axios library will be using the
// special configuration (base URL, authentication, access token)
setupAxiosDefaultsAndInterceptors(axios);

Vue.mixin(titleMixin);
Vue.use(BootstrapVue);
Vue.use(VTooltip);
Vue.use(IconsPlugin);
Vue.use(VueFormulate, {
  // @ts-ignore
  uploader: async (file, progress, error) => uploadToBlobStorage(file, progress, error)
});

new Vue({
  // @ts-ignore
  store,
  render: h => h(App),
  // @ts-ignore
  router: router
}).$mount('#mounting_node');